import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled, CardGroup } from "../../../components/Card";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { isRoleForAction } from "../../../hooks/useRoles";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { Device, DeviceSettings } from "../../../models/device";
import { LiftSettingsAllowedRoles } from "../../../models/role";

export interface DeviceSettingsCardProps {
  device: Device;
}

export const DeviceSettingsCard = ({ device }: DeviceSettingsCardProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();

  const handleEdit = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditDeviceSettings,
      tenantID: cliftState.currentTenant.id,
      device,
    });
  };

  const getSubscriptionRange = (settings: DeviceSettings) => {
    if (settings?.subscriptionStartDate && settings?.subscriptionEndDate) {
      return (
        new Date(settings.subscriptionStartDate).toLocaleDateString() +
        " - " +
        new Date(settings.subscriptionEndDate).toLocaleDateString()
      );
    }
    return t("subscription_dates_not_set", { ns: "lift" });
  };

  return (
    <Card
      title={t("device_settings_title", { ns: "lift" })}
      editAllowed={isRoleForAction(LiftSettingsAllowedRoles)}
      onEdit={handleEdit}
    >
      <CardDetailTitled
        title={t("device_ui_language", { ns: "lift" })}
        value={device.deviceSettings?.deviceUiLanguage.toString()}
      />
      <CardDetailTitled
        title={t("subscription_validity", { ns: "lift" })}
        value={getSubscriptionRange(device.deviceSettings)}
      />
      <CardDetailTitled
        title={t("lift_gui_telemetry_enabled", { ns: "lift" })}
        value={
          device.deviceSettings?.liftGuiTelemetryEnabled
            ? t("enabled", { ns: "translation" })
            : t("disabled", { ns: "translation" })
        }
      />
      <CardDetailTitled
        title={t("lift_ecu_update_interval", { ns: "lift" })}
        value={device.deviceSettings?.liftEcuUpdateInterval?.toString()}
      />
      <CardDetailTitled
        title={t("battery_voltage_read_interval", { ns: "lift" })}
        value={device.deviceSettings?.batteryVoltageReadInterval?.toString()}
      />
      <CardDetailTitled
        title={t("network_state_update_interval", { ns: "lift" })}
        value={
          device.deviceSettings?.networkStateTelemetryEnabled
            ? device.deviceSettings?.networkStateUpdateInterval?.toString()
            : t("disabled", { ns: "translation" })
        }
      />
      <CardDetailTitled
        title={t("runtime_info_update_interval", { ns: "lift" })}
        value={
          device.deviceSettings?.runtimeInfoTelemetryEnabled
            ? device.deviceSettings?.runtimeInfoUpdateInterval?.toString()
            : t("disabled", { ns: "translation" })
        }
      />
      <CardDetailTitled
        title={t("temperature_update_interval", { ns: "lift" })}
        value={
          device.deviceSettings?.temperatureTelemetryEnabled
            ? device.deviceSettings?.temperatureUpdateInterval?.toString()
            : t("disabled", { ns: "translation" })
        }
      />

      {/** OTA download  */}
      <CardGroup title={t("ota_download_settings_title", { ns: "lift" })}>
        <CardDetailTitled
          title={t("ota_download_url", { ns: "lift" })}
          value={device.deviceSettings?.otaDownloadSettings?.url}
        />
        <CardDetailTitled
          title={t("ota_download_token", { ns: "lift" })}
          value={device.deviceSettings?.otaDownloadSettings?.token}
        />
        <CardDetailTitled
          title={t("ota_download_controller_id", { ns: "lift" })}
          value={device.deviceSettings?.otaDownloadSettings?.controllerId}
        />
      </CardGroup>
      {/** logs download  */}
      <CardGroup title={t("logs_download_settings_title", { ns: "lift" })}>
        <CardDetailTitled
          title={t("logs_download_url", { ns: "lift" })}
          value={device.deviceSettings?.logsDownloadSettings?.url}
        />
        <CardDetailTitled
          title={t("logs_download_token", { ns: "lift" })}
          value={device.deviceSettings?.logsDownloadSettings?.token}
        />
        <CardDetailTitled
          title={t("logs_download_token_valid_for", { ns: "lift" })}
          value={device.deviceSettings?.logsDownloadSettings?.tokenValidFor}
        />
      </CardGroup>
      {/** commissioning data download  */}
      <CardGroup
        title={t("commissioning_data_download_settings_title", { ns: "lift" })}
      >
        <CardDetailTitled
          title={t("commissioning_data_download_url", { ns: "lift" })}
          value={device.deviceSettings?.commissioningDataDownloadSettings?.url}
        />
        <CardDetailTitled
          title={t("commissioning_data_download_token", { ns: "lift" })}
          value={
            device.deviceSettings?.commissioningDataDownloadSettings?.token
          }
        />
        <CardDetailTitled
          title={t("commissioning_data_download_token_valid_for", {
            ns: "lift",
          })}
          value={
            device.deviceSettings?.commissioningDataDownloadSettings
              ?.tokenValidFor
          }
        />
      </CardGroup>
    </Card>
  );
};
