import {
  CommissioningDataDownloadSettings,
  DeviceUILanguage,
  LogsDownloadSettings,
  OtaDownloadSettings,
} from "models/device";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";

export type EditDeviceSettingsRequest = {
  deviceUiLanguage: DeviceUILanguage;
  otaDownloadSettings: OtaDownloadSettings;
  logsDownloadSettings: LogsDownloadSettings;
  commissioningDataDownloadSettings: CommissioningDataDownloadSettings;
};

export const editDeviceSettings = (
  tenantId: number,
  deviceId: number,
  request: EditDeviceSettingsRequest
): Promise<void> => {
  return fetch(`/api/tenant/${tenantId}/device/${deviceId}/settings`, {
    method: "PUT",
    body: JSON.stringify({
      ...request,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
};
