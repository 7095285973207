import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { fetchCustomer } from "../../services/customers-api";
import { ContextMenu } from "../../components/ContextMenu";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { isRoleForAction } from "../../hooks/useRoles";
import {
  EditCustomerAllowedRoles,
  DeleteCustomerAllowedRoles,
} from "../../models/role";

export const CustomerOptionsMenu = ({
  customerID,
  setSelectedCustomerID,
}: {
  customerID: number;
  setSelectedCustomerID(customerID: number): void;
}) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();

  const handleEditCustomer = async () => {
    return fetchCustomer(cliftState.currentTenant!.id, customerID).then(
      (res) => {
        dispatchCliftState({
          type: CliftReducerAction.EditCustomer,
          customer: res,
          customerID,
        });
      }
    );
  };

  return (
    <ContextMenu
      items={[
        {
          id: "edit",
          title: t("edit"),
          hidden:
            customerID === null || !isRoleForAction(EditCustomerAllowedRoles),
          onClicked: handleEditCustomer,
          icon: <EditIcon />,
        },
        {
          id: "delete",
          title: t("delete", { ns: "customer" }),
          hidden:
            customerID === null || !isRoleForAction(DeleteCustomerAllowedRoles),
          onClicked: () => setSelectedCustomerID(customerID),
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
